.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

img {
  max-width:100%;
  object-fit: contain;
}

.main-table {
  max-width: 100%;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse
}
p {
  font-size: small;
}
@media (min-width:641px){
  td {
    text-align: left;
    font-size: medium;
    padding-right: 50px;
  }  
}
@media only screen and (max-width: 600px) {
  td {
    text-align: left;
    font-size: medium;  
    padding-right: 10px; 
  }
}

.main-td {
  border: 1px solid white;
  padding: 10px;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  font-family: sans-serif;
  -webkit-font-smoothing: auto;
  -moz-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: auto;
  text-rendering: optimizeLegibility;
  font-smooth: always;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

h1 {
  font-size: 1.5rem;
}